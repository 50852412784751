import React, {useState, useEffect} from "react";
import { Link, graphql } from "gatsby";
import { useIntl, FormattedDate, changeLocale } from "gatsby-plugin-intl";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from "../components/Layout";
import Seo from "../components/seo";

export default function Blog({data}) {
  const intl = useIntl();
  const locale = intl.locale !== "en" ? `/${intl.locale}` : "";
  const [postData, setPostData] = useState([]);

  useEffect(()=>{
    if(intl.locale !=="en"){
      changeLocale(intl.locale);
    }
    let postArray = [];
    switch(intl.locale){
      case "hy":
        data.allContentfulBlogPost.edges.map((edge) => {
          const tempHy = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleHy ?
              edge.node.titleHy.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            summary: edge.node.descriptionHy.descriptionHy ?
              edge.node.descriptionHy.descriptionHy.trim():
              edge.node.summary.summary.trim(),
            }
          postArray = [...postArray, tempHy]
        })
        setPostData(postArray);
        break;
      case "ru":
        data.allContentfulBlogPost.edges.map((edge) => {
          const tempRu = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleRu ?
              edge.node.titleRu.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            summary: edge.node.descriptionRu.descriptionRu ?
              edge.node.descriptionRu.descriptionRu.trim():
              edge.node.summary.summary.trim(),
            }
          postArray = [...postArray, tempRu]
        })
        setPostData(postArray);
        break;
      case "fa":
        data.allContentfulBlogPost.edges.map((edge) => {
          const tempFa = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleFa ?
              edge.node.titleFa.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            summary: edge.node.descriptionFa.descriptionFa ?
              edge.node.descriptionFa.descriptionFa.trim():
              edge.node.summary.summary.trim(),
            }
          postArray = [...postArray, tempFa]
        })
        setPostData(postArray);
        break;
      case "fr":
        data.allContentfulBlogPost.edges.map((edge) => {
          const tempFr = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleFr ?
              edge.node.titleFr.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            summary: edge.node.descriptionFr.descriptionFr ?
              edge.node.descriptionFr.descriptionFr.trim():
              edge.node.summary.summary.trim(),
            }
          postArray = [...postArray, tempFr]
        })
        setPostData(postArray);
        break;
      default:
        data.allContentfulBlogPost.edges.map((edge) => {
          const tempEN = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.title.trim(),
            date: edge.node.date,
            summary: edge.node.summary.summary.trim(),
          }
          postArray = [...postArray, tempEN]
        })
        setPostData(postArray);
        break;
    }
  },[
    intl.locale,
    data.allContentfulBlogPost,
  ])

  return (
    <Layout isHome={false}>
      <div className="container">
        <h1>{intl.formatMessage({ id: "Blog" })}</h1>
          <div className="post-feed">
            {postData.map((item) => (
              <article key={item.id}>
                <Link
                  className="post-card" 
                  to={item.to}>
                    <header className="post-card-header">
                      <GatsbyImage
                      className="post-card-image" 
                      image= {getImage(item.image)}
                      alt={item.alt}
                      />
                      <h2 className="post-card-title">{item.title}</h2>
                    </header>
                    <time>
                      <FormattedDate
                        className="post-card-excerpt"
                        value={new Date(item.date)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    </time>
                    <h3 className="post-card-excerpt">{item.summary}</h3>
                </Link>
              </article>
            ))}

          </div>
      </div>
    </Layout>
  );
}
export const Head = ({ pageContext }) => (
  <Seo
  title={`${pageContext.intl.messages["Blog"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
  article={false}>
  </Seo>
)

export const articlesQuery = graphql`
query PostQuery {
  allContentfulBlogPost(sort: {fields: date, order: DESC}) {
    edges {
      node {
        id
        date
        slug
        title
        heroImageAlt
        heroImage {
          gatsbyImageData
        }
        summary {
          summary
        }
        descriptionFa {
          descriptionFa
        }
        descriptionFr {
          descriptionFr
        }
        descriptionHy {
          descriptionHy
        }
        descriptionRu {
          descriptionRu
        }
        titleFa
        titleFr
        titleHy
        titleRu
      }
    }
  }
}
`;
