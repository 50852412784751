import React, {useRef} from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const Seo = ({ 
  descriptionEn,
  descriptionFa,
  descriptionFr,
  descriptionHy,
  descriptionRu, 
  titleEn,
  titleFa,
  titleFr,
  titleHy,
  titleRu,
  language,
  article,
  brand,
  title,
  description
}) => {
  const { pathname } = useLocation()
  const { title: defaultTitle, description: defaultDescription, image, siteUrl } = useSiteMetadata();
  const articleTitle=useRef("");
  const articleDescription = useRef("");
  
  switch(language){
    case "en":
      articleTitle.current = `${titleEn} | ${brand}`
      articleDescription.current = descriptionEn
    break;
    case "hy":
      articleTitle.current = `${titleHy} | ${brand}`
      articleDescription.current = descriptionHy
    break;
    case "ru":
      articleTitle.current = `${titleRu} | ${brand}`
      articleDescription.current = descriptionRu
    break;
    case "fa":
      articleTitle.current = `${titleFa} | ${brand}`
      articleDescription.current = descriptionFa
    break;
    case "fr":
      articleTitle.current = `${titleFr} | ${brand}`
      articleDescription.current = descriptionFr
    break;
  }
  
  const seo = {
    title: article ? articleTitle.current : title || defaultTitle,
    description: article ? articleDescription.current : description || defaultDescription,
    image: image,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <>
      {/* <html lang={intl.locale} /> */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}
    </>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}